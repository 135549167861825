/**
 * main.ts
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Components
import App from "./App.vue";
import spinner from "@/components/helperComponents/spinner.vue";
import loader from "@/components/helperComponents/loader.vue";
import * as Sentry from "@sentry/vue";

// Composables
import { createApp } from "vue";
import router from "./router";

// Plugins
import { registerPlugins } from "@/plugins";

const app = createApp(App);

registerPlugins(app);

Sentry.init({
  app,
  environment: import.meta.env.NODE_ENV,
  dsn: "https://912b1e74fc6296969ffa6e16e39c35fe@o4506740210663424.ingest.sentry.io/4506740228685824",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.browserProfilingIntegration(),
    Sentry.vueIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
    Sentry.metrics.metricsAggregatorIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/admin.insur-link\.com\//],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.component("loader", loader);
app.component("spinner", spinner);
app.mount("#app");
