import { createWebHistory, createRouter } from "vue-router";
import { RouteRecordRaw } from "vue-router";
import { useCookies } from "vue3-cookies";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/LoginView.vue"),
    meta: { title: "Login Page" },
  },
  {
    path: "/:pathMatch(.*)",
    redirect: "/404",
  },
  {
    path: "/404",
    component: () => import("@/views/Error404View.vue"),
  },
  {
    path: "/",
    name: "app",
    component: () => import("@/Layouts/LoggedInLayout.vue"),
    redirect: "/dashboard",
    meta: { title: "Insur-Link Dashboard" },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/DashboardView.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/users",
        name: "users",
        component: () => import("@/views/UsersView.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/payers",
        name: "payers",
        component: () => import("@/views/PayersView.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/providers",
        name: "providers",
        component: () => import("@/views/ProvidersView.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/priceList",
        name: "pricelist",
        component: () => import("@/views/PriceListView.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/profile",
        name: "profile",
        component: () => import("@/views/ProfileSettingsView.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/roles",
        name: "roles",
        component: () => import("@/views/RolesView.vue"),
        meta: { requiresAuth: true },
      },
    ],
  },
];

const whiteList = ["/login"]; // no redirect whitelist

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const isJwtToken = useCookies().cookies.isKey("jwtToken");
  if (isJwtToken) {
    if (to.name === "login") {
      next({ name: "app" });
    } else {
      next();
      return;
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      next({ name: "login" });
    }
  }
});

export default router;
